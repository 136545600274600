import moment from 'moment';
import { Constants } from './Constants';

export const dateString = (d) => {
  let format1 = 'YYYY-MM-DD';
  if (d !== null || d !== undefined || d !== '') {
    let a = new Date(d);
    return moment(a.setDate(a.getDate())).format(format1);
  } else {
    return '';
  }
};

export const formatDate = (d) => {
  let format1 = 'YYYY-MM-DD';
  if (d !== null || d !== undefined || d !== '') {
    let a = new Date(d);
    return moment(a).format(format1);
  } else {
    return '';
  }
};

export const dateStringForPayload = (dateParam) => {
  let format1 = 'YYYY-MM-DD';
  if (!dateParam) return '';
  let thisDate = new Date(dateParam);
  if (
    checkDayLightSaving(dateParam) &&
    new Date(dateParam).toDateString() === new Date().toDateString()
  ) {
    return moment(thisDate.setDate(thisDate.getDate() + 1)).format(format1);
  } else {
    return moment(thisDate.setDate(thisDate.getDate())).format(format1);
  }
};

const checkDayLightSaving = (dateParam) => {
  let date1 = new Date();
  let timeZoneOffset = new Date(dateParam).getTimezoneOffset() / 60;

  //get user timezone name
  let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //identify daylight is observed or not
  date1.stdTimezoneOffset = function () {
    let jan = new Date(this.getFullYear(), 0, 1);
    let jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  };
  date1.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
  };

  //Check for user timezone and daylight
  if (userTimezone !== 'America/Chicago') return false;
  let today = date1;

  //If daylight is observed and timezone offset is GMT-5hrs & time is past 6PM then date changes in UTC
  return (
    today.isDstObserved() && timeZoneOffset === 5 && today.getHours() >= 18
  );
};

export const calculateTheAge = (dob) => {
  if (dob) {
    const ageInYears = moment().diff(
      moment(new Date(dob), 'MM/DD/YYYY'),
      'years',
    );
    return ageInYears;
  }
  return 0;
};

export const getbuAbbrevation = () => {
  const buAbbrevation = JSON.parse(localStorage.getItem('roper')).buAbbrevation;
  return buAbbrevation;
};

export const getUserisPilotUser = () => {
  const isPilotUser = JSON.parse(localStorage.getItem('roper')).isPilotUser;
  return isPilotUser ? true : false;
};

/*
  This function is created to use Wherever [[BUSHORTNAME]] comes from backend, replace it with Business Name.
*/
export const formatBuShortName = (description) => {
  const businessUnitFullName = JSON.parse(
    localStorage.getItem('roper'),
  )?.businessName;
  if (!businessUnitFullName) {
    return description;
  }
  return description?.replace(`[[BUSHORTNAME]]`, businessUnitFullName);
};

export const capitalizeFirstLetter = (inputString) => {
  // Convert the entire string to lowercase
  let lowerCaseString = inputString?.toLowerCase();

  // Capitalize the first letter
  let capitalizedString =
    lowerCaseString?.charAt(0)?.toUpperCase() + lowerCaseString?.slice(1);

  return inputString ? capitalizedString : '-';
};

export const capitalizeWordsFirstLetter = (inputString) => {
  // Split the input text into an array of words
  const words = inputString?.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words?.map(
    (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase(),
  );

  // Join the words back into a single string and return the result
  return capitalizedWords?.join(' ');
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = /^(1)?(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
  if (match) {
    return [match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
};

export const handleNoNumbers = (e) => {
  if (
    Constants.alphabet.includes(e.key) ||
    Constants.customChars.includes(e.key) ||
    Constants.buttons.includes(e.key) ||
    (e.ctrlKey && Constants.copyPaste.includes(e.key))
  ) {
    // if this condition satisfies then type in
  } else {
    e.preventDefault();
  }
};

export const handleAlphabetsAndNumbers = (e) => {
  if (
    Constants.alphabet.includes(e.key) ||
    Constants.customChars.includes(e.key) ||
    Constants.numbers.includes(e.key) ||
    Constants.numPadChars.includes(e.key) ||
    Constants.buttons.includes(e.key) ||
    (e.ctrlKey && Constants.copyPaste.includes(e.key))
  ) {
    // if this condition satisfies then type in
  } else {
    e.preventDefault();
  }
};

export const handleValidationOnNoNumbers = (e) => {
  let regex = /^[a-zA-Z-. ]*$/;
  if (e.target.value.trim() !== '') {
    return !regex.test(e.target.value.trim());
  } else {
    return false;
  }
};

export const handleValidationOnAlphaNumberic = (e) => {
  let regex = /^[a-zA-Z0-9-+*/.]+$/;
  if (e.target.value.trim() !== '') {
    return !regex.test(e.target.value.trim());
  } else {
    return false;
  }
};

export const commaSeparatedPrice = (param) => {
  if (param) {
    const numberFormatter = Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${numberFormatter.format(param)}`;
  } else {
    return ``;
  }
};

export const handleOnlyNumbers = (e) => {
  if (
    Constants.numbers.includes(e.key) ||
    Constants.buttons.includes(e.key) ||
    ['.'].includes(e.key) ||
    (e.ctrlKey && Constants.copyPaste.includes(e.key))
  ) {
    // if this condition satisfies then type in
  } else {
    e.preventDefault();
  }
};

export const addArrayFilter = (arrayFilter) => {
  const isSelectAll =
    arrayFilter?.filter((item) => item.value === 'select_all')?.length > 0;
  const filteredArray = arrayFilter
    ?.filter((item) => item?.value !== 'select_all')
    ?.map((item) => item?.value);
  return !isSelectAll && filteredArray && filteredArray.length > 0
    ? [...filteredArray]
    : undefined;
};

export const getFormattedPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  } else {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    const lastTenDigits = cleanedNumber?.slice(-10);
    return lastTenDigits.length === 10
      ? lastTenDigits?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      : phoneNumber;
  }
};
